export default function SupportInfoImage({imgSrc, imgAlt}) {
  return (
    <div className="relative h-56 bg-blue-800 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
      <img
        height="100%"
        width="100%"
        className="w-full h-full object-cover"
        src={imgSrc}
        alt={imgAlt}
      />
      <div
        aria-hidden="true"
        className="absolute inset-0 bg-gradient-to-r from-blue-500 to-blue-800"
        style={{mixBlendMode: 'multiply'}}></div>
    </div>
  );
}
