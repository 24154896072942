export default function SupportInfoSectionText({
  subtitle,
  title,
  titleDescription,
  textContent,
}) {
  return (
    <>
      <h2 className="text-base font-semibold uppercase tracking-wider text-blue-200">
        {subtitle}
      </h2>
      <p className="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl">
        {title}{' '}
      </p>
      <p className="mt-3 text-lg text-blue-100">
        {titleDescription}
        {}
      </p>
      <p className="mt-3 text-lg text-blue-100">{textContent}</p>
    </>
  );
}
