import SupportInfoImage from '../SupportInfoImage/SupportInfoImage';
import SupportInfoLinks from '../SupportInfoLinks/SupportInfoLinks';
import SupportInfoSectionText from '../SupportInfoSectionText/SupportInfoSectionText';
import SupportInfoContentLayout from '../SupportInfoContentLayout/SupportInfoContentLayout';
import LazyLoad from 'react-lazyload';

const defaultSupportInfoLinkContent = {
  supportInfoLinks: [
    {
      text: 'Read and ask questions',
      href: 'https://docs.musicfox.io/b00st',
    },
    {
      href: 'mailto:support@tearsheetads.com',
      text: `Email your support team`,
    },
  ],
};

const defaultSupportInfoImageContent = {
  imgSrc: 'https://res.cloudinary.com/tincre/image/upload/v1639607220/tearsheetads.com/AdobeStock_321829714_ocarxu_hinalp.jpg',
  imgAlt:
    'Two arms link in support of one another; a.k.a. like tearsheetads support helping its users.',
};
const defaultSupportInfoSectionContent = {
  title: 'Our team is here to help',
  subtitle: 'Helpful Support',
  titleDescription: `We rock when you rock. It's that simple.`,
  textContent: `So we make it easy to get help. Join the tearsheetads community or email support. And don't miss our top-notch docs and instructionals.`,
};
export default function SupportInfo({supportInfoContent}) {
  const supportInfo =
    typeof supportInfoContent !== 'undefined' && !!supportInfoContent
      ? supportInfoContent
      : {
        ...defaultSupportInfoImageContent,
        ...defaultSupportInfoSectionContent,
        ...defaultSupportInfoLinkContent,
      };
  return (
    <div className="relative bg-blue-900">
      <LazyLoad once height={1000} offset={100}>
        <SupportInfoImage {...supportInfo} />
      </LazyLoad>
      <SupportInfoContentLayout>
        <SupportInfoSectionText {...supportInfo} />
        <SupportInfoLinks {...supportInfo} />
      </SupportInfoContentLayout>
    </div>
  );
}
