import DocsButton from '../DocsButton/DocsButton';

export default function SupportInfoLinks({supportInfoLinks}) {
  if (typeof supportInfoLinks !== 'undefined' && !!supportInfoLinks) {
    return (
      <>
        <div className="mt-8">
          <div className="inline-block rounded-md shadow w-full">
            <DocsButton {...supportInfoLinks[0]} />
          </div>
        </div>

        <div className="mt-8 block">
          <div className="block rounded-md shadow w-full">
            <DocsButton {...supportInfoLinks[1]} />
          </div>
        </div>
      </>
    );
  }
  return null;
}
